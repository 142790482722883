import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Stack from "../../components/stack"
import Text from "../../components/text"

import Columns from "../../components/columns"
import Column from "../../components/column"
import Container from "../../components/container"
import Image from "../../components/image"
import Button from "../../components/button"
import Box from "../../components/box"
import Heading from "../../components/heading"

const BookHoelderlinLiebt = () => {
  const data = useStaticQuery(graphql`
    query BookHoelderlinLiebtHegelHoelderlin {
      katalogCover: file(
        relativePath: { eq: "hoelderlin-liebt/buch-cover.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 240, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Box bg="muted" p={[6, 8, 12]}>
      <Columns
        alignY="center"
        space={[6, 8, 12]}
        collapse={[true, false]}
        reverse={[false, true]}
      >
        <Column width="content">
          <Box width={["24", "40"]}>
            <Image
              image={data.katalogCover.childImageSharp.gatsbyImageData}
              alt="Titelbild des Buches zeigt eine Illustration von Hölderlin am Schreibtisch."
            />
          </Box>
        </Column>
        <Column>
          <Stack space={6}>
            <Stack space={6}>
              <Heading level={5}>Das Buch zur Ausstellung</Heading>
              <Container maxWidth="lg" center={false}>
                <Text size={[2, 3]} sans>
                  <Text as="em">Hölderlin leuchtet</Text>
                  <br />
                  Ein Lese-Buch über Friedrich Hölderlin in Einfacher Sprache
                  von Stephanie Jaeckel mit Illustrationen von Jette von
                  Bodecker <br />
                  Herausgegeben und mit einem Nachwort von Sandra Potsch
                </Text>
              </Container>
            </Stack>
            <Stack space={1}>
              <Text size={1} sans>
                ISBN 978-3-945653-26-5
              </Text>
              <Text size={1} sans>
                115 Seiten, 46 farbige Abbildungen
              </Text>
            </Stack>
            <Button secondary href="https://www.tuebingen.de/1520.html#/1952">
              Jetzt bestellen
            </Button>
          </Stack>
        </Column>
      </Columns>
    </Box>
  )
}

export default BookHoelderlinLiebt
